<template>
  <div>
    <div>
      <div class="gradation">
        <div 
          class="absolute w-full gradation" 
          :style="{ 
            backgroundImage : 
              'linear-gradient(to bottom, rgba(51, 51, 51, 0), #222222)'
          }"
        />
        <div 
          class="absolute w-full bg-white gradation" 
          :style="{ opacity }"
        />
      </div>
      <div class="history_image">
        <div 
          class="history_background bg-[#222222]" 
          :style="{ 
            backgroundImage: 
              'linear-gradient(to bottom, rgba(51, 51, 51, 0), rgba(0, 0, 0, 0.5))',
          }"
        />
        <div
          class="bg-white history_background"
          :style="{ opacity }"
        />
        <img
          v-if="$screen.breakpoint === 'pc'"
          class="object-cover history_background"
          :src="require('@/assets/about/cookie.png')"
          alt=""
          data-aos="fade-right"
          data-aos-duration="900"
        >
      </div>

      <!-- <div
        ref="text1"
        class="flex flex-col justify-center items-center w-full relative pc:h-[172vh] tablet:h-[123vh] mobile:h-[174vh]"
      > -->
      <div
        ref="text1"
        class="relative flex flex-col items-center justify-center w-full min-h-[123vh]"
        :style="{ height: `${historyHeight}px`}"
      >
        <div
          ref="history_top" 
          class="z-[1] mb-[10vh] w-full absolute pc:top-[-60vh] tablet:top-[-75vh] mobile:top-[-90vh]"
        >
          <div class="flex w-full ">
            <div
              v-if="$screen.breakpoint === 'pc'"
              class="flex-1"
            />
            <div class="flex-1 ">
              <div
                class="flex flex-col text-white bold info_text pc:w-[655px] pt-[-160vh] pc:px-0 mobile:px-[16px]"
                data-aos="fade-up"
                data-aos-duration="900"
                :style="'word-wrap: break-word; word-break: keep-all;'"
              >
                <div>
                  쿡앱스는 지금도
                  <br>
                  <span class="text-orange-500">'세상을 즐겁게'</span> 
                  <br v-if="$screen.breakpoint === 'mobile'">
                  만들고 있습니다
                </div>
              </div>

              <div
                v-if="$screen.breakpoint === 'pc'"
                class="flex flex-col text-orange-500 bold info_text mt-[64px]"
              >
                <div
                  v-for="(info, i) in infos"
                  :key="i"
                  data-aos="fade-up"
                  data-aos-duration="900"
                  class="grid grid-cols-[8px,4px,32px,1fr] mb-[44px] grid-rows-[26px,64px]"
                >
                  <div class="row-span-3" />
                  <div class="row-span-3 bg-gray-500" />
                  <div class="row-span-3" />
                  <div class=" info_title">
                    {{ info.title }}
                  </div>
                  <div class="flex items-end">
                    <div class="info_number">
                      {{ info.number }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="text-orange-500 mt-[48px]"
              >
                <div
                  v-for="(info, i) in infos"
                  :key="i"
                  class="flex flex-col items-center info"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <div class=" info_title mb-[6px]">
                    {{ info.title }}
                  </div>
                  <div class="flex items-end info_num_wrap">
                    <div class="info_number">
                      {{ info.number }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div 
          class="text-red-500 text-[50px] z-[1] mb-[100vh] w-full absolute pc:top-[33vh] mobile:top-[10vh] h-[100vh]"
        >
          <div
            class="flex w-full"
            :class="$screen.breakpoint !== 'moblie' ? 'px-[16px]' : ''"
          >
            <div
              v-if="$screen.breakpoint === 'pc'"
              class="flex-1 "
            />
            <div
              class="flex flex-col flex-1"
              :class="$screen.breakpoint !== 'pc' ? 'items-center' : ''"
            >
              <div
                class="flex flex-col text-orange-500 font-semibold history_text mb-[32px]"
              >
                <p
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  History of CookApps
                </p>
              </div>
              <div 
                ref="history_bottom" 
                class="relative"
              >
                <div
                  class="grid"
                  :class="$screen.breakpoint === 'mobile' ? 'grid-cols-[60px,14px,1fr]' : 'grid-cols-[90px,14px,1fr]' "
                >
                  <div class="col-start-2 row-span-3">
                    <div class="relative h-full">
                      <div
                        data-aos="fade-up"
                        data-aos-duration="900"
                        class="Rectangle absolute left-[5px] top-[10px]"
                      />
                    </div>
                  </div>

                  <div
                    v-for="(d, i) in desc"
                    :key="`y${i}`"
                    :class="`col-start-1 row-start-${i+1} year`"
                    data-aos="fade-up"
                    data-aos-duration="900"
                    v-html="d.year"
                  />

                  <div
                    v-for="(d, i) in desc"
                    :key="`d${i}`"
                    class="desc pc:pb-[32px] mobile:pb-[40px]"
                    :class="`col-start-3 row-start-${i+1} ${ i===2 ? 'mb-[80px]' : ''}`"
                    data-aos="fade-up"
                    data-aos-duration="900"
                  >
                    <div 
                      v-for="(dd) in d.desc" 
                      :key="dd"
                    >
                      <div
                        class="Oval top-[10px] left-[-14px]"
                      />
                      <div
                        class="flex items-center pc:mb-[14px] mobile:mb-[10px]"
                        data-aos="fade-up"
                        data-aos-duration="900"
                      >
                        <div
                          class="w-[3px] h-[3px] bg-white mr-[10px]"
                          style="border-radius: 100%"
                        />
                        <span>{{ dd }}</span> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      scrollY: 0,
      elScrollTop: 0,
      opacity:0,
      infos:[{
        title: '서비스 국가 수',
        number: '250곳+'
      }, {
        title: '누적 유저 수',
        number: '4억 명+'
      }, {
        title: '런칭 게임 수',
        number: '400개+'
      }, {
        title: '하루 평균 유저 수',
        number: '150만 명+'
      },],
      desc: [{
        year: '~ 현재<br>2021',
        desc: [
          '2023년 테일드 데몬 슬레이어:라이즈 인기 1위 달성(Google)',
          '2023년 무명기사단 인기 1위 달성(Google, iOS)',
          '2023년 광전사키우기 인기 1위 달성(Google, iOS)',
          '2022년 매출 830억, 영업이익 187억 달성',
          '2022년 ‘테일드 데몬 슬레이어’ 구글 올해의 캐주얼 게임 ‘우수상’ 수상',
          '2022년 대한민국 중소기업 규제혁신 장관상 수상',
          '2022년 누적 유저 수 4억 명 달성',
          '2022년 삼국지 키우기 인기 1위 달성(Google)',
          '2022년 테일드 데몬 슬레이어 인기 1위 달성(Google, iOS)',
          '2021년 매출 545억, 영업이익 159억 달성',
          '2021년 글로벌 다운로드 부문 국내 퍼블리셔 9위 선정'
        ]
      },
      {
        year: '~ 2020<br>2016',
        desc: [
          '2020년 Home Design 시리즈 1억 1천만 다운로드 달성',
          '페이스북 캔버스 게임에서 모바일 게임으로 체제 전환',
          'Nstage, CookApps Play, CookApps 106 관계사 설립',
          '2016년 매출 248억, 영업이익 77억 달성'
        ]
      },
      {
        year: '~ 2015<br>2010',
        desc: [
          '페이스북 플랫폼 Top Publisher 25위 선정',
          '누적 유저 수 1억 명 돌파',
          '2010년 주식회사 쿡앱스 설립',
        ]
      }],
      historyHeight: '123vh'
    };
  },
  created () {
    
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
    this.historyHeight = this.$screen.breakpoint === 'pc' ? this.$refs.history_top.offsetHeight + this.$refs.history_bottom.offsetHeight : this.$refs.history_top.offsetHeight/2 + this.$refs.history_bottom.offsetHeight;
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      this.show = window.top.scrollY >= this.$refs.text1.offsetTop - window.innerHeight;
      this.scrollY = window.top.scrollY ;
      this.elScrollTop = this.$refs.text1.offsetTop - window.innerHeight - 800;
      this.opacity= 1 - (this.scrollY - this.elScrollTop) / (window.innerHeight / 2);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/about.scss";
.history_image{
  position: sticky;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.history_background {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.gradation{
  height: 350px;
}

:is(.pc) {
  .info_text {
    font-family: Pretendard;
    font-size: 44px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.4px;
  }
  .info_title {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.2px;
    color: #bebebe;
  }
  .info_number {
    font-family: Pretendard;
    font-size: 56px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 56px;
    letter-spacing: normal;
  }

  .history_text {
    // font-family: Pretendard;
    font-family: $fontfamilyClash;
    font-size: 44px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: -0.4px;
  }

  .year {
    padding-right: 24px;
    margin-top: 4px;
    font-family: Pretendard;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.2px;
    text-align: right;
    color: #ff7f00;
  }

  .desc {
    position: relative;
    padding-left: 25px;
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.2px;
    color: white;
  }
}
:is(.tablet) {
  .info_text {
    font-family: Pretendard;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.3px;
    text-align: center;
  }

  .info{
    margin-bottom: 44px;
    .info_title {
      height: 26px;
      font-family: Pretendard;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: -0.2px;
      text-align: center;
      color: #bebebe;
    }
    .info_num_wrap {
      height: 64px;
    }
    .info_number {
      font-family: Pretendard;
      font-size: 56px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 56px;
      letter-spacing: normal;
      text-align: center;
    }
  }
  .history_text {
    // font-family: Clash Display;
    font-family: $fontfamilyClash;
    font-size: 44px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 54px;
    letter-spacing: -0.4px;
  }
  .year {
    padding-right: 29px;
    margin-top: 4px;
    font-family: Pretendard;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 26px;
    letter-spacing: -0.2px;
    text-align: right;
    color: #ff7f00;
  }

  .desc {
    position: relative;
    padding-left: 43px;
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 32px;
    letter-spacing: -0.2px;
    color: white;
  }
}
:is(.mobile) {
  .info_text {
    font-family: Pretendard;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.3px;
    text-align: center;
  }
  .info{
    margin-bottom: 32px;
    .info_title {
      font-family: Pretendard;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: -0.2px;
      color: #bebebe;
    }
    .info_num_wrap {
      height: 46px;
    }
    .info_number {
      font-family: Pretendard;
      font-size: 36px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 36px;
      letter-spacing: -0.3px;
    }
  }

  .history_text {
    // font-family: Clash Display;
    font-family: $fontfamilyClash;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 38px;
    letter-spacing: -0.3px;
  }

  .year {
    padding-right: 14px;
    margin-top: 4px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: -0.1px;
    text-align: right;
    color: #ff7f00;
  }

  .desc {
    position: relative;
    padding-left: 12px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: -0.1px;
    color: white;
  }
}


.Oval {
  position: absolute;
  width: 14px;
  height: 14px;
  outline: solid 3px #343a3c;
  background-color: #ff7f00;
  border-radius: 50%;
}

.Rectangle {
  width: 4px;
  height: 100%;
  background-image: linear-gradient(to bottom, #ff7f00, rgba(255, 127, 0, 0));
}

.row-start-1 {
  grid-row-start: 1
}
.row-start-2 {
  grid-row-start: 2
}
.row-start-3 {
  grid-row-start: 3
}



</style>